<script>
export default {
  props: {
    value: { type: String },
  },

  data() {
    return {
      modal: false,
      date: this.value,
    }
  },

  watch: {
    date(to) {
      this.$emit('update:value', to)
    },
  },
}
</script>

<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-model="date" readonly v-bind="$attrs" v-on="on"></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(date)"> OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>
