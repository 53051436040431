<script>
import NewSecretSantaEventForm from '@/features/friends/components/NewSecretSantaEventForm'

export default {
  components: {
    NewSecretSantaEventForm,
  },
}
</script>

<template>
  <div>
    <NewSecretSantaEventForm />
  </div>
</template>
