<script>
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import store from '@/infrastructure/store'
import { onMounted } from '@vue/composition-api'
import DatePicker from '@/infrastructure/dates/components/DatePicker'

export default {
  components: {
    DatePicker,
  },

  setup() {
    const { isBusy: isLoading, execute: loadFriends } = useAsyncLoading({
      actionFactory: () => store.dispatch('friends/fetchAllFriendRequests'),
    })

    const { isBusy: isInviting, execute: createInvite } = useAsyncLoading({
      actionFactory: ({ userIds, eventName, eventDate, budgetAmount }) =>
        store.dispatch('friends/createSecretSantaInvite', { userIds, eventName, eventDate, budgetAmount }),
    })

    onMounted(loadFriends)

    return {
      isLoading,

      isInviting,
      createInvite,
    }
  },

  data() {
    const now = new Date()
    return {
      eventName: `Secret Santa ${now.getFullYear()}`,
      eventDate: `${now.getFullYear()}-12-25`,
      budgetAmount: null,
      selectedFriendIndexes: [],
    }
  },

  computed: {
    acceptedFriends: () => store.getters['friends/acceptedFriendRequests'],

    selectedFriendUserIds() {
      return this.selectedFriendIndexes.map((idx) => this.acceptedFriends[idx].friendUserId)
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>Secret Santa</v-card-title>

      <v-divider />

      <v-card-text>
        <div class="pt-5"></div>

        <div>
          <v-text-field label="Name" v-model="eventName" type="text" filled hide-details></v-text-field>
        </div>

        <div class="pt-3"></div>

        <div>
          <DatePicker label="Event date" :value.sync="eventDate" append-icon="mdi-calendar" filled hide-details />
        </div>

        <div class="pt-3"></div>

        <div>
          <v-text-field label="Budget" v-model.number="budgetAmount" type="number" filled hide-details clearable></v-text-field>
        </div>

        <div class="pt-10"></div>

        <v-divider />

        <div class="pt-5"></div>

        <v-list subheader flat>
          <v-subheader>Who will be joining?</v-subheader>

          <v-list-item-group v-model="selectedFriendIndexes" multiple>
            <v-list-item v-for="friend in acceptedFriends" :key="friend.friendUserId">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title v-text="friend.friendFullName"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar>
                  <v-img :src="friend.friendPicture"></v-img>
                </v-list-item-avatar>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div class="pt-10"></div>

        <v-divider />

        <div class="pt-5"></div>

        <v-btn
          color="success"
          :disabled="isInviting || selectedFriendIndexes.length < 2"
          :loading="isInviting"
          block
          @click="createInvite({ userIds: selectedFriendUserIds, eventName: eventName, eventDate: eventDate, budgetAmount: budgetAmount })"
        >
          <v-icon left>mdi-send</v-icon>
          Create invite
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
